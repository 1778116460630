<template>
    <div>
        <sub-header :titles="subTitle" @refresh="refresh"></sub-header>
        <el-row>
            <exec-log-detail1 ref="cz1" v-show="cz_types === '1'"
                              @btnClick1="btnClick1"
                              @btnClick2="btnClick2">
            </exec-log-detail1>
            <exec-log-detail2 ref="cz2" v-if="cz_types === '2'" :ex-info="exInfo"
                              @backList="backList"></exec-log-detail2>
            <exec-log-detail3 ref="cz3" v-if="cz_types === '3'" :ex-info="exInfo"
                              @backList="backList"></exec-log-detail3>
        </el-row>
    </div>
</template>

<script>
    import subHeader from '@/views/components/SubHeader'
    import batchRecordErrorDetail from '@/views/pages/components/batch-record-error-detail'
    import execLogDetail1 from '@/views/pages/components/exec-log-detail1'
    import execLogDetail2 from '@/views/pages/components/exec-log-detail2'
    import execLogDetail3 from '@/views/pages/components/exec-log-detail3'
    export default {
        name: "batchRecord",
        components: {subHeader, batchRecordErrorDetail, execLogDetail1, execLogDetail2, execLogDetail3},
        data() {
            return {
                exInfo: {},
                title: '',
                subTitle: '',
                editRow: {},
                cz_types: '1'
            }
        },
        mounted() {
        },
        methods: {
            btnClick1(info) {
                this.exInfo = info;
                this.cz_types = '2';
                this.subTitle = 'データ明細';
            },
            btnClick2(info) {
                this.exInfo = info;
                this.cz_types = '3';
                this.subTitle = 'バッチ明細';
            },
            /**
             * 更新
             * */
            refresh() {
                this.$refs['cz'+this.cz_types].searchBatch();
            },
            /**
             * 検索詳細リスト
             * */
            searchDetailList() {
                this.searchBatch();
            },
            backList() {
                this.cz_types = '1';
                this.subTitle = ''
            },
        }
    }
</script>

<style lang="scss" scoped>


  .content {
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 30px 30px;
    height: calc(100vh - 380px);
    background: rgba(255, 255, 255, 1);

    .pagina-box {
      margin-top: 20px;
      text-align: right;
    }
  }

  /deep/ .el-header {
    padding: 0;
    box-shadow: rgba(170, 170, 170, 35%) 0px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .el-button {
    font-weight: 400;
    padding: 12px 12px;
    border-radius: 0px;
    border: 1px solid #f2f2f2;
  }

  /deep/ .el-input__inner {
    border: 1px solid #f2f2f2;
  }

  /deep/ .el-dialog__header {
    text-align: left;
  }

  /deep/ .el-dialog__body {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  /deep/ .el-dialog__footer {
    padding-top: 20px;
    padding-bottom: 0px;
    padding-bottom: 30px;
    text-align: left !important;

    .el-button:nth-child(2) {
      background: rgba(102, 190, 150, 1);
    }
  }

  > > > .el-table {
    .el-table__body-wrapper {
      height: calc(100% - 48px) !important;
    }
  }
  .dialog-title-box {
    span:nth-child(1) {
      font-weight: bold;
      font-size: 18px;
    }
  }
</style>
